// ----------------------------------------------------------------------

export default function Snackbar() {
    return {
        MuiSnackbarContent: {
            styleOverrides: {
                root: {}
            }
        }
    };
}
